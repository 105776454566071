import AudioApi from '@money.energy/audio-api';
import { ISongs, SlotId } from '../../config';
import {
  coinsValueNormalize,
  getBGMSoundByGameMode,
  getPlaceBetResult,
  getSpinResult,
  getWinStage,
} from '../../gameUtils';
import { EventTypes, GameMode, ISettledPlaceBet, UserBonus } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsSlotBusy,
  setLastRegularWinAmount,
  setLastSpinData,
  setReelSets,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses, slotBetGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { eventManager, PopupTypes, WinStages } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { States } from '../config';
import { Logic } from '../index';
import { BaseController } from './BaseController';

export class FreeSpinController extends BaseController {
  public override gameMode: GameMode = GameMode.FREE_SPINS;

  public static override the = new FreeSpinController();

  protected constructor() {
    super();
  }

  public override enterIdleState(_prevState: States): void {
    setIsSlotBusy(false);
    if (setCurrentBonus().currentRound === setCurrentBonus().rounds) {
      AudioApi.fadeOut(1000, getBGMSoundByGameMode(this.gameMode));
      PopupController.the.openPopup(PopupTypes.FREE_SPINS_END);
      eventManager.once(EventTypes.END_FREE_SPINS, () => {
        this.getLastSpinData().then((res) => {
          PopupController.the.closeCurrentPopup();
          setLastSpinData(res);
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.BASE_GAME);
        });
      });
      return;
    }
    setTimeout(() => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND), 500);
  }

  public override enterSpinState(_prevState: States): void {
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    SlotMachine.the().spinSpinAnimation();
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });
  }

  public override enterJingleState(_prevState: States): void {
    if (setCurrentBonus().currentRound === setCurrentBonus().rounds) {
      setTimeout(() => {
        Logic.the.changeState(States.IDLE);
      }, 1000);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override setResult(result: ISettledPlaceBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    setFreeSpinsTotalWin(setFreeSpinsTotalWin() + result.bet.result.winCoinAmount);
    result.bet.result.spinResult = spinResult;
    setBetResult(result);
  }

  public override enterController(_prevGameMode: GameMode): void {
    // if (setBrokenGame()) AudioApi.play({ type: ISongs.BGM_FS_Loop });
    setIsSlotBusy(false);
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setFreeSpinsTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.IDLE);
      return;
    }

    if (setIsContinueAutoSpinsAfterFeature()) {
      // AudioApi.play({ type: ISongs.BGM_FS_Loop });
      if (setIsDuringBigWinLoop()) {
        // AudioApi.fadeOut(0, ISongs.BGM_FS_Loop);
      }
      Logic.the.changeState(States.IDLE);
    } else {
      eventManager.once(EventTypes.START_FREE_SPINS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.IDLE);
      });
      PopupController.the.openPopup(PopupTypes.FREE_SPINS);
    }
  }

  public async getLastSpinData(): Promise<{
    reelPositions: number[];
    layout: SlotId[][];
  }> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    // todo replace with real backend logic
    const { betId } = res.data.userBonuses[0]!;
    const bet = await client.query<ISettledPlaceBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });
    const { reelPositions, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      winCountAmount: bet.data.bet.result.winCoinAmount,
      reelSetId: bet.data.bet.reelSetId,
    } as { reelPositions: number[]; winCountAmount: number; reelSetId: string };
    const layout = setReelSets().find((reelSet) => reelSet.id === reelSetId)?.layout;
    return { reelPositions, layout: layout || [] };
  }

  public override enterAfterWinState(_prevState: States): void {
    this.onEnterAfterWinState();
  }

  private onEnterAfterWinState(): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getPlaceBetResult(setBetResult()).bet.result;
    setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
    const multiplier = coinsValueNormalize(winCoinAmount) / coinsValueNormalize(setBetAmount());
    if (multiplier > 7) {
      AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
      return;
    }
    if (multiplier >= 5) {
      AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
      return;
    }
    if (multiplier >= 3) {
      AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
    }
  }

  public override enterWinLinePresentationState(_prevState: States): void {
    const betResult: ISettledPlaceBet = getPlaceBetResult(setBetResult());
    const winLines = betResult.paylines.filter((winLine) => !winLine.winPositions.includes(9));
    const { winCoinAmount } = betResult.bet.result;

    if (setCurrentBonus().currentRound === setCurrentBonus().rounds) {
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        if (setFreeSpinsTotalWin() > 0) {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
        }
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.once(EventTypes.MOVE_MULTIPLIER_END, () => {
        eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
        eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, winLines);
      });
      eventManager.emit(EventTypes.MOVE_MULTIPLIER_START);
    } else {
      if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
        eventManager.once(EventTypes.COUNT_UP_END, () => {
          if (setFreeSpinsTotalWin() > 0) {
            eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
          }
          Logic.the.changeState(States.AFTER_WIN);
        });
        eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
      } else {
        eventManager.once(EventTypes.WIN_LINE_ANIMATION_END, () => {
          if (setFreeSpinsTotalWin() > 0) {
            eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
          }
          Logic.the.changeState(States.AFTER_WIN);
        });
        eventManager.emit(EventTypes.START_COUNT_UP, 0, winCoinAmount, 0);
      }

      eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, winLines);
    }
  }

  public override exitController(_nextGameMode: GameMode): void {
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    // AudioApi.stop({ type: ISongs.BGM_FS_Loop });
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getPlaceBetResult(setBetResult()).balance.settled);
  }
}
