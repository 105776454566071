import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { formatNumber } from '@money.energy/utils-fe';
import { config } from '../../config';
import { coinsValueNormalize, countCoins, isShownCurrency } from '../../gameUtils';
import { setCurrency, setSlotConfig } from '../../gql/cache';
import type { ISlotHistory, ISlotHistoryData } from '../../gql/d';
import { slotHistoryGql } from '../../gql/query';
import styles from './history.module.scss';

const formatHistory = (historyData: ISlotHistoryData['edges']) => {
  const history = _.map(
    _.map(historyData, (elem) => ({
      ...elem.node,
      cursor: elem.cursor,
    })),
    (elem) => {
      const date = _.get(elem, 'createdAt', Date.now());
      const baseCoinAmount = _.get(elem, 'coinAmount', 1);
      const coinAmount = elem.userBonus
        ? _.get(elem, 'userBonus.bonus.coinAmount', 1) * baseCoinAmount
        : baseCoinAmount;
      const coins = countCoins({
        coinValue: _.get(elem, 'coinValue', 1),
        coinAmount,
        lines: setSlotConfig().lineSets[0]!.coinAmountMultiplier,
      });
      const bet = formatNumber({ currency: setCurrency(), value: coins, showCurrency: isShownCurrency(setCurrency()) });
      const win = formatNumber({
        currency: setCurrency(),
        value: coinsValueNormalize(elem.result.winCoinAmount),
        showCurrency: isShownCurrency(setCurrency()),
      });

      return {
        date,
        win,
        bet,
        gameId: elem.id,
        cursor: elem.cursor,
      };
    },
  );

  return _.reverse(history);
};

const renderKey = (item: ISlotHistory, key: string) => {
  if (key === 'date') {
    const dateString = new Date(item[key as keyof ISlotHistory] as string).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
    const formattedDate = dateString.replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2-$1-$3');
    return formattedDate;
  }

  if (key === 'bet') {
    return item[key as string];
  }

  return item[key as string];
};

const HistoryComponent: React.FC = () => {
  const { head, showBy } = config.historyTable;
  const { t } = useTranslation();
  const [items, setItems] = useState<ISlotHistory[]>([]);
  const [pageAmount, setPageAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cursorLastRef = useRef('');
  const cursorFirstRef = useRef('');
  const isFetchingRef = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { data: historyData, fetchMore } = useQuery<
    { bets: ISlotHistoryData },
    {
      input: {
        first?: number;
        last?: number;
        before?: string;
        after?: string;
        filter?: { slotId?: string };
      };
    }
  >(slotHistoryGql, {
    variables: {
      input: { last: showBy, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (historyData?.bets?.edges.length) {
      cursorLastRef.current = historyData.bets.edges[historyData.bets.edges.length - 1]!.cursor;
      cursorFirstRef.current = historyData.bets.edges[0]!.cursor;
      setPageAmount(Math.ceil(_.get(historyData, 'bets.pageInfo.count', 0) / showBy));
      setItems((prevItems) => _.uniqBy([...prevItems, ...formatHistory(historyData.bets.edges)], 'gameId'));
    }
  }, [historyData, showBy]);

  const loadMore = useCallback(() => {
    if (isFetchingRef.current || currentPage >= pageAmount) {
      return;
    }
    isFetchingRef.current = true;
    fetchMore({
      variables: {
        input: {
          last: showBy,
          before: cursorFirstRef.current,
          filter: { slotId: setSlotConfig().id },
        },
      },
    })
      .then((fetchResult) => {
        const newEdges = fetchResult.data.bets.edges;
        if (newEdges.length > 0) {
          cursorFirstRef.current = fetchResult.data.bets.edges[0]!.cursor;
          setItems((prevItems) => _.uniqBy([...prevItems, ...formatHistory(newEdges)], 'gameId'));
          setCurrentPage((prevPage) => prevPage + 1);
        }
        isFetchingRef.current = false;
      })
      .catch((error) => {
        console.error('Error fetching more data:', error);
        isFetchingRef.current = false;
      });
  }, [fetchMore, showBy, currentPage, pageAmount]);

  const handleScroll = useCallback(
    (e) => {
      const container = e.currentTarget;
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop - clientHeight < 50) {
        loadMore();
      }
    },
    [loadMore],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  if (!historyData?.bets) {
    return (
      <div className={styles['history']}>
        <div className={styles['spinner']} />
      </div>
    );
  }

  return (
    <div className={styles['history']}>
      <div className={styles['history__title']}>
        <span>{t('gameHistory')}</span>
      </div>
      <div className={styles['history__wrap']}>
        <div className={styles['header']}>
          <div className={`${styles['header-item']} ${styles['date']}`}>{t('date')}</div>
          <div className={`${styles['header-item']} ${styles['bet']}`}>{t('bet')}</div>
          <div className={`${styles['header-item']} ${styles['win']}`}>{t('win')}</div>
        </div>
        <div className={styles['container']} ref={containerRef}>
          {items.map((item) => (
            <div key={item.gameId} className={styles['history-item']}>
              {Object.keys(head).map((key) => (
                <div key={key} className={`${styles['history-item__col']} ${styles[key as string]}`}>
                  {key === 'gameId' && t(key)} <span className={styles.value}>{renderKey(item, key)}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(HistoryComponent);
