import i18n from 'i18next';
import _ from 'lodash';
import { Container, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { ISongs } from '../../config';
import {
  calcPercentage,
  coinsValueNormalize,
  countCoins,
  isMobileLandscape,
  isMobilePortrait,
  isShownCurrency,
  isTabletLandscape,
  isTabletPortrait,
  normalizeBalanceValue,
} from '../../gameUtils';
import { EventTypes, IUserBalance } from '../../global.d';
import { setBetAmount, setBrokenGame, setCoinAmount, setCurrency, setSlotConfig, setWinAmount } from '../../gql/cache';
import client from '../../gql/client';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { getUserGql, getWinAmountGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import {
  BTN_GAP_DESKTOP_H,
  BTN_GAP_DESKTOP_W,
  BTN_GAP_MOBILE_H,
  BTN_GAP_MOBILE_W,
  BTN_GAP_TABLET_H,
  BTN_GAP_TABLET_W,
  BTN_UI_DESKTOP_H,
  BTN_UI_DESKTOP_W,
  BTN_UI_MOBILE_H,
  BTN_UI_MOBILE_W,
  BTN_UI_TABLET_H,
  BTN_UI_TABLET_W,
  eventManager,
  gameNameTextStyle,
} from '../config';
import { LineSet } from '../d';
import Clock from '../ui/clock';
import { bottomContainerTextStyle, bottomContainerValueStyle } from './bottomBarConfig';

export default class BottomBar extends ViewContainer {
  private isLoaded = false;

  private isAutoSpins = false;

  private betSettings: IBetSettings;

  private betAmount: number;

  private coinMultiplier: number;

  private currency = 'FUN';

  private credit: TextField;

  private winTitle: TextField;

  private win: TextField;

  private lastWinValue: string;

  private bet: TextField;

  private clock: Clock;

  private gameName: TextField;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private creditContainer: Container;

  private betContainer: Sprite;

  private winContainer: Container;

  constructor() {
    super();
    this.zIndex = 2;
    this.betSettings = getBetsSetting();
    this.coinMultiplier = (setSlotConfig().lineSets[0] as LineSet).coinAmountMultiplier;
    this.betAmount = this.getBetAmount(setBetAmount());
    this.currency = setCurrency();
    this.credit = this.initCredit();
    this.bet = this.initBet();
    this.clock = new Clock();
    this.creditContainer = this.initCreditContainer();
    this.winTitle = new TextField(`${i18n.t('lastWin')}`, 250, 100, bottomContainerTextStyle);
    this.winContainer = this.initWinContainer();
    this.betContainer = this.initBetContainer();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.win = this.initWin();
    this.lastWinValue = this.win.getText().text;
    this.gameName = this.initGameName();
    this.creditContainer.addChild(this.credit.getText(), this.gameName.getText());
    this.betContainer.addChild(this.minusBtn, this.bet.getText(), this.plusBtn);
    this.winContainer.addChild(this.win.getText());
    this.addChild(this.creditContainer, this.betContainer, this.winContainer);
    eventManager.on(EventTypes.UPDATE_BOTTOM_BAR_BET, () => {
      this.updateBetAmount(setBetAmount());
      this.win.setText(`${this.bet.getText().text}`);
    });
    eventManager.addListener(EventTypes.UPDATE_WIN_VALUE, this.updateWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_USER_BALANCE, this.updateUserBalance.bind(this));
    this.getUserBalance();
    this.updateBetAmount(setCoinAmount() * this.coinMultiplier);
    this.getWinAmount();
    this.handleDisable();

    eventManager.addListener(EventTypes.OPEN_POPUP_BG, () => this.handleOpenedPopups(false));
    eventManager.addListener(EventTypes.CLOSE_POPUP_BG, () => this.handleOpenedPopups(true));
    eventManager.addListener(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      if (isAutoSpins) {
        this.minusBtn.disable();
        this.plusBtn.disable();
      } else {
        this.handleDisable();
      }
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy || this.isAutoSpins;
      this.handleDisable();
    });
  }

  private initCreditContainer(): Container {
    const container = new Container();
    const title = new TextField(`${i18n.t('credit')}`, 250, 100, bottomContainerTextStyle);
    title.text.position.set(0, 0);
    container.addChild(title.getText());
    return container;
  }

  private initWinContainer(): Container {
    const container = new Container();
    this.clock.position.set(0, 106);
    this.winTitle = new TextField(`${i18n.t('lastWin')}`, 250, 100, bottomContainerTextStyle);
    this.winTitle.text.anchor.set(1, 0);
    this.winTitle.text.position.set(0, 0);
    container.addChild(this.winTitle.getText(), this.clock);
    return container;
  }

  private initCredit(): TextField {
    const credit = new TextField('0', 270, 100, bottomContainerValueStyle);
    credit.text.anchor.set(0, 0);
    credit.text.position.set(0, 35);
    return credit;
  }

  private initGameName(): TextField {
    const gameName = new TextField('Winter Cocktail', 250, 100, gameNameTextStyle);
    gameName.text.anchor.set(0, 1);
    gameName.text.position.set(0, 106);
    return gameName;
  }

  private initWin(): TextField {
    const value = formatNumber({
      currency: this.currency,
      value: coinsValueNormalize(
        countCoins({
          totalAmount: setWinAmount(),
        }),
      ),
      showCurrency: true,
    });
    const win = new TextField(value, 250, 100, bottomContainerValueStyle);
    win.text.visible = !setBrokenGame();
    win.text.anchor.set(1, 0);
    win.text.position.set(0, 35);

    return win;
  }

  private initBet(): TextField {
    const value = formatNumber({
      currency: this.currency,
      value: coinsValueNormalize(countCoins({ totalAmount: setBetAmount() })),
      showCurrency: true,
    });
    const bet = new TextField(value, 180, 100, bottomContainerValueStyle);
    bet.text.anchor.set(0.5);
    bet.text.position.set(0, 10);

    return bet;
  }

  private initBetContainer(): Sprite {
    const container = new Sprite(Texture.from(ResourceTypes.betBackground));
    const bet = new TextField(i18n.t('bet'), 250, 100, bottomContainerTextStyle);
    bet.text.anchor.set(0.5, 0);
    bet.text.position.set(0, -40);
    container.addChild(bet.getText());
    container.anchor.set(0.5);
    return container;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betMinusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betMinusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: () => this.handleMinus(),
      onTouchStart: () => this.handleMinus(),
    });
    minusBtn.name = 'minusBtn';
    minusBtn.y = 0;
    minusBtn.x = -128;
    minusBtn.anchor.set(1, 0.5);

    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betPlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betPlusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betPlusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: () => this.handlePlus(),
      onTouchStart: () => this.handlePlus(),
    });
    plusBtn.name = 'plusBtn';
    plusBtn.y = 0;
    plusBtn.x = 128;
    plusBtn.anchor.set(0, 0.5);

    return plusBtn;
  }

  private handleMinus(): void {
    if (this.betSettings.bets[this.betAmount]! > this.betSettings!.minBet) {
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount]);
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.UI_BetChange });
      this.updateBetAmount(setCoinAmount() * this.coinMultiplier);
    }
  }

  private handlePlus(): void {
    if (this.betSettings.bets[this.betAmount]! < this.betSettings!.maxBet) {
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount]);
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.UI_BetChange });
      this.updateBetAmount(setCoinAmount() * this.coinMultiplier);
    }
  }

  private handleDisable(): void {
    const bet = this.betSettings.bets[this.betAmount];

    if (bet === this.betSettings!.minBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }
    if (this.isSlotBusy) {
      this.minusBtn.disable();
      this.plusBtn.disable();
    }
  }

  private handleOpenedPopups(isOpened: boolean): void {
    this.betContainer.visible = isOpened;

    if (isOpened) {
      this.winTitle.setText(i18n.t('lastWin'));
      this.win.setText(this.lastWinValue);
    } else {
      this.winTitle.setText(i18n.t('bet'));
      this.win.setText(`${this.bet.getText().text}`);
    }
  }

  private getBetAmount(betAmount: number): number {
    return _.findIndex(this.betSettings!.bets, (bet) => {
      return bet === betAmount / this.coinMultiplier;
    });
  }

  private getWinAmount(): void {
    client.watchQuery<{ winAmount: number }>({ query: getWinAmountGql }).subscribe(({ data }) => {
      this.updateWinAmount(data.winAmount);
    });
  }

  private getUserBalance(): void {
    client
      .query({
        query: getUserGql,
      })
      .then((res) => {
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          (res as unknown as { data: { user: { balance: IUserBalance } } }).data.user.balance,
        );
      });
  }

  private async updateUserBalance(balance: { currency: string; amount: number }): Promise<void> {
    if (!this.isLoaded) {
      this.currency = balance.currency;
      this.updateBetAmount(setBetAmount());
      this.updateWinAmount(setWinAmount());

      this.isLoaded = true;
    }
    this.updateBalance(balance.amount);
  }

  private updateWinValue(newValue: number): void {
    this.win.setText(`${newValue}`);
    this.lastWinValue = `${newValue}`;
  }

  private updateBalance(amount: number): void {
    this.credit.setText(
      `${formatNumber({
        currency: this.currency,
        value: normalizeBalanceValue(amount),
        showCurrency: isShownCurrency(this.currency),
      })}`,
    );
  }

  private updateWinAmount(amount: number): void {
    const updatedWinAmount = `${formatNumber({
      currency: this.currency,
      value: coinsValueNormalize(countCoins({ totalAmount: amount })),
      showCurrency: isShownCurrency(this.currency),
    })}`;
    this.win.setText(updatedWinAmount);
    this.lastWinValue = updatedWinAmount;
  }

  private updateBetAmount(amount: number): void {
    this.bet.setText(
      `${formatNumber({
        currency: this.currency,
        value: coinsValueNormalize(countCoins({ totalAmount: amount })),
        showCurrency: isShownCurrency(this.currency),
      })}`,
    );
    eventManager.emit(EventTypes.UPDATE_BET);
  }

  protected override resize(width: number, height: number): void {
    const isPortrait = width < height;

    const ratio = width / height > 2.15;
    let deviceSize = 0;
    let btnSize = ratio ? BTN_UI_DESKTOP_H : BTN_UI_DESKTOP_W;
    let btnGap = ratio ? BTN_GAP_DESKTOP_H : BTN_GAP_DESKTOP_W;

    if (isTabletLandscape(width, height)) {
      btnSize = ratio ? BTN_UI_TABLET_H : BTN_UI_TABLET_W;
      btnGap = ratio ? BTN_GAP_TABLET_H : BTN_GAP_TABLET_W;
    }
    if (isTabletPortrait(width, height)) {
      btnSize = BTN_UI_TABLET_W;
      btnGap = BTN_GAP_TABLET_W;
    }
    if (isMobileLandscape(width, height)) {
      btnSize = ratio ? BTN_UI_MOBILE_H : BTN_UI_MOBILE_W;
      btnGap = ratio ? BTN_GAP_MOBILE_H : BTN_GAP_MOBILE_W;
    }
    if (isMobilePortrait(width, height)) {
      btnSize = BTN_UI_MOBILE_W;
      btnGap = BTN_GAP_MOBILE_W;
    }

    if (ratio) {
      deviceSize = height;
    } else {
      deviceSize = width;
    }

    if (isPortrait) {
      deviceSize = height;
    }

    const size = calcPercentage(deviceSize, btnSize);
    const gap = calcPercentage(deviceSize, btnGap);

    const containerHeightScale = size / 96;

    this.betContainer.scale.set(containerHeightScale);
    this.betContainer.y = -size / 2 - gap;
    this.betContainer.x = width / 2;

    this.creditContainer.scale.set(containerHeightScale);
    this.winContainer.scale.set(containerHeightScale);

    this.creditContainer.position.set(gap, -this.creditContainer.height - gap);
    this.winContainer.position.set(width - gap, -this.winContainer.height - gap);

    if (isPortrait) {
      this.betContainer.y = -size * 2 - gap * 1.4;
    }

    this.position.set(0, height);
  }
}
