import type React from 'react';
import type { IToggleProps } from './d';
import styles from './toggle.module.scss';

const Toggle: React.FC<IToggleProps> = (props) => {
  const { id, className = '', onChange, checked, disabled } = props;

  return (
    <label
      className={`uiSwitch ${styles['switch'] as string} ${
        (checked ? `uiSwitch_checked ${styles['checked']}` : '') as string
      } ${className}`}
      htmlFor={id}
    >
      <input onChange={onChange} id={id} type="checkbox" disabled={disabled as boolean} checked={checked as boolean} />
      <span className={styles['slider'] as string} />
    </label>
  );
};

export default Toggle;
