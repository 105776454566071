import React, { useEffect, useRef } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { setIsProceedToGame } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { slotConfigGql } from '../../gql/query';
import { Logic } from '../../logic';
import EventListener from '../EventListener';
import Menu from '../Menu';
import AutoPlaySettingsMenu from '../UI/AutoPlaySettings/AutoPlaySettingsMenu';
import InfoPopup from '../UI/Info/InfoPopup';
import Spin from '../UI/SpinButton';
import styles from './slotScreen.module.scss';

(window as unknown as { PIXI: unknown }).PIXI = PIXI;

const SlotScreen: React.FC = () => {
  const { data: slotConfig } = useQuery<IConfig>(slotConfigGql);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const isProceedToGame = useReactiveVar(setIsProceedToGame);
  const { isSoundOn } = slotConfig!;
  useEffect(() => {
    pixiContainerRef.current?.appendChild(Logic.the.application.view);
    Logic.the.init();
  }, []);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  return (
    <>
      <div className={styles['main']!} ref={pixiContainerRef} />
      {isProceedToGame && (
        <>
          <EventListener />
          <AutoPlaySettingsMenu />
          <InfoPopup />
          <Menu />
          <Spin />
        </>
      )}
    </>
  );
};

export default SlotScreen;
