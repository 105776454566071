import { Sprite, Texture } from 'pixi.js';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { PopupProps } from '../d';

export abstract class Popup extends ViewContainer {
  private backdrop = new Sprite(Texture.from(ResourceTypes.backdrop));
  constructor() {
    super();
    this.zIndex = 2;
    this.backdrop.alpha = 0.8;
    this.addChild(this.backdrop);
  }

  public hide(): void {}

  public show(_props?: PopupProps): void {}
}
