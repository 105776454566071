import { Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import { setIsAutoSpins, setIsPopupOpeningInProgress } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import {
  buyBonusClickTextStyle,
  buyBonusHoverTextStyle,
  buyBonusTextStyle,
  buyFeatureDisableTextStyle,
  eventManager,
  PopupTypes,
} from '../config';
import { PopupController } from '../popups/PopupController';

class BuyBonusBtn extends ViewContainer {
  private btn: Sprite;

  private text: TextField;

  isDisabled: boolean;

  resizedWidth: number;

  resizedHeight: number;

  constructor() {
    super();
    this.x = -840;
    this.y = 0;
    this.isDisabled = false;
    this.text = this.initBuyFeatureText();
    this.btn = this.initBuyBonusBtn();
    this.resizedWidth = 0;
    this.resizedHeight = 0;
    this.addChild(this.btn);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.FREE_SPINS:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private initBuyBonusBtn() {
    const btn = new Sprite(Texture.from(ResourceTypes.buyBonusDefault));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (_e) => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.UI_ButtonHover });
        this.btn.texture = Texture.from(ResourceTypes.buyBonusHover);

        this.text.text.style = buyBonusHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyBonusHover);

        this.text.text.style = buyBonusTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyBonusHover);

        this.text.text.style = buyBonusClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyBonusHover);

        this.text.text.style = buyBonusTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): TextField {
    const text = new TextField(i18n.t<string>('buyBonusBtn'), 95, 200, buyBonusTextStyle);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(35, 0);
    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      AudioApi.play({ type: ISongs.UI_MenuOpen });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = Texture.from(ResourceTypes.buyBonusDisabled);

      this.text.text.style = buyFeatureDisableTextStyle;
      this.text.text.alpha = 0.75;
    } else {
      this.btn.texture = Texture.from(ResourceTypes.buyBonusDefault);

      this.text.text.style = buyBonusTextStyle;
      this.text.text.alpha = 1;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  protected override resize(width: number, height: number): void {
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
  }

  private handlePosition(): void {
    const isLandscape = this.resizedWidth >= this.resizedHeight;
    if (isLandscape) {
      this.scale.set(1);
      this.x = -840;
      this.y = 0;
    } else {
      this.scale.set(1.5);
      this.x = 0;
      this.y = 540;
    }
  }
}

export default BuyBonusBtn;
