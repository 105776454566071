export const CAROUSEL_IMAGE_HEIGHT = 700;
export const CAROUSEL_DOTS_DEFAULT_COLOR = '#a29d9e';
export const CAROUSEL_DOTS_ACTIVE_COLOR = '#ffffff';
export const CAROUSEL_DOTS_BLUR_SIZE = 5;
export const CAROUSEL_DOTS_SIZE = 16;
export const CAROUSEL_DOTS_GAP = 8;
export const CAROUSEL_TEXT_SIZE = 50;
export const CAROUSEL_TEXT_LINE_HEIGHT = 120;
export const CAROUSEL_TEXT_COLOR = 0xffee51;
export const CAROUSEL_TEXT_STROKE_COLOR = 0x420a69;
export const CAROUSEL_TEXT_SHADOW = 0x000000;
export const CAROUSEL_TEXT_DIVIDE_COLOR = 0xfcef24;
export const CAROUSEL_TEXT_WORD_WRAP_WIDTH = undefined;
export const CAROUSEL_ANIMATION_DELAY = 4000;
export const CAROUSEL_ANIMATION_DURATION = 500;

export const DEFAULT_CAROUSEL_TEXT = {
  fill: CAROUSEL_TEXT_COLOR,
  fontSize: CAROUSEL_TEXT_SIZE,
  wordWrap: true,
  stroke: CAROUSEL_TEXT_STROKE_COLOR,
  strokeThickness: 9,
  fontWeight: '700',
  align: 'center',
  trim: true,
};
