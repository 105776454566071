import {
  calcPercentage,
  canPressSpin,
  isMobileLandscape,
  isMobilePortrait,
  isTabletLandscape,
  isTabletPortrait,
} from '../../gameUtils';
import { EventTypes, GameMode } from '../../global.d';
import { setIsPopupOpened } from '../../gql/cache';
import { Logic } from '../../logic';
import {
  BTN_GAP_DESKTOP_H,
  BTN_GAP_DESKTOP_W,
  BTN_GAP_MOBILE_H,
  BTN_GAP_MOBILE_W,
  BTN_GAP_TABLET_H,
  BTN_GAP_TABLET_W,
  BTN_SPIN_DESKTOP_H,
  BTN_SPIN_DESKTOP_W,
  BTN_SPIN_MOBILE_H,
  BTN_SPIN_MOBILE_W,
  BTN_SPIN_TABLET_H,
  BTN_SPIN_TABLET_W,
  eventManager,
} from '../config';
import { UiButton } from '../ui/uiButton';

class SpinBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isSlotStopped = false;

  constructor() {
    super('spin');
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOTS_STOPPED, (isSlotStopped: boolean) => {
      this.isSlotStopped = isSlotStopped;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
  };

  private handleUpdateIntent = (): void => {
    if (this.isAutoSpins) {
      this.handleDisable();
    } else {
      this.updateIntent(this.isSlotBusy ? 'skip' : 'spin');
    }
  };

  private handleDisable = (): void => {
    const isDisable = !canPressSpin({
      gameMode: Logic.the.controller.gameMode,
      isFreeSpinsWin: false,
      isSpinInProgress: this.isSpinInProgress,
      isSlotBusy: this.isSlotBusy,
      isSlotStopped: this.isSlotStopped,
      isPopupOpened: setIsPopupOpened(),
      isAutoPlay: this.isAutoSpins,
    });

    this.setDisable(isDisable);
  };

  private handleClick = (): void => {
    if (!this.isDisabled) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
      eventManager.emit(EventTypes.TOGGLE_SPIN);
    }
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0.5);
    const ratio = this.applicationSize.width / this.applicationSize.height > 2.15;
    let deviceSize = 0;
    let btnSize = ratio ? BTN_SPIN_DESKTOP_H : BTN_SPIN_DESKTOP_W;
    let btnGap = ratio ? BTN_GAP_DESKTOP_H : BTN_GAP_DESKTOP_W;

    if (isTabletLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_SPIN_TABLET_H : BTN_SPIN_TABLET_W;
      btnGap = ratio ? BTN_GAP_TABLET_H : BTN_GAP_TABLET_W;
    }
    if (isTabletPortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_SPIN_TABLET_W;
      btnGap = BTN_GAP_TABLET_W;
    }
    if (isMobileLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_SPIN_MOBILE_H : BTN_SPIN_MOBILE_W;
      btnGap = ratio ? BTN_GAP_MOBILE_H : BTN_GAP_MOBILE_W;
    }
    if (isMobilePortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_SPIN_MOBILE_W;
      btnGap = BTN_GAP_MOBILE_W;
    }

    if (ratio) {
      deviceSize = this.applicationSize.height;
    } else {
      deviceSize = this.applicationSize.width;
    }

    if (this.isPortraitMode) {
      deviceSize = this.applicationSize.height;
    }

    const size = calcPercentage(deviceSize, btnSize);
    const gap = calcPercentage(deviceSize, btnGap);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height / 2;
    this.setSize(size);

    if (this.isPortraitMode) {
      this.btn.anchor.set(0.5);
      x = this.applicationSize.width / 2;
      y = this.applicationSize.height - size * 2 - gap;
    }

    this.x = x;
    this.y = y;
  }
}

export default SpinBtn;
