import {
  calcPercentage,
  isMobileLandscape,
  isMobilePortrait,
  isRegularMode,
  isTabletLandscape,
  isTabletPortrait,
} from '../../gameUtils';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  setAutoSpinsLeft,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenAutoplayPopup,
  // setIsOpenBetSettingsPopup,
  // setIsOpenHistoryPopup,
  // setIsOpenInfoPopup,
  setIsPopupOpened,
  setIsPopupOpeningInProgress,
  setIsSlotBusy,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnWinExceeds,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { TextField } from '../components/TextField';
import {
  autoPlayCounterTextStyle,
  BTN_GAP_DESKTOP_H,
  BTN_GAP_DESKTOP_W,
  BTN_GAP_MOBILE_H,
  BTN_GAP_MOBILE_W,
  BTN_GAP_TABLET_H,
  BTN_GAP_TABLET_W,
  BTN_UI_DESKTOP_H,
  BTN_UI_DESKTOP_W,
  BTN_UI_MOBILE_H,
  BTN_UI_MOBILE_W,
  BTN_UI_TABLET_H,
  BTN_UI_TABLET_W,
  eventManager,
} from '../config';
import { UiButton } from '../ui/uiButton';

class AutoplayBtn extends UiButton {
  private count: TextField;

  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  constructor() {
    super('autospin-off');
    this.interactive = !this.isDisabled;
    this.count = this.initCount();
    this.addChild(this.count.getText());
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
    this.zIndex = 1;
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.count.text.visible = isAutoSpins;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_AUTO_SPINS_LEFT, (autoSpinsLeft: string) => {
      this.count.text.text = autoSpinsLeft;
      this.count.text.visible = !!autoSpinsLeft && this.isAutoSpins;
      this.setScaleCountText();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });

    eventManager.addListener(EventTypes.FORCE_STOP_AUTOPLAY, () => {
      if (this.isAutoSpins) {
        setIsStopOnBalanceDecrease(false);
        setIsStopOnBalanceIncrease(false);
        setIsStopOnAnyWin(false);
        setIsStopOnWinExceeds(false);
        setAutoSpinsLeft(0);
        setIsAutoSpins(false);
        this.isAutoSpins = false;
        this.updateIntent('autospin-off');
      }
    });
  }

  private initCount(): TextField {
    const count = new TextField('499', 200, 100, autoPlayCounterTextStyle);
    count.text.visible = false;
    count.text.anchor.set(0.5);
    count.text.y = 0;
    count.text.x = 0;

    return count;
  }

  private setScaleCountText(): void {
    const scaleValue = this.btn.width / (110 / 100);
    this.count.text.scale.set(scaleValue / 60);
  }

  private handleUpdateIntent(): void {
    if (this.isAutoSpins) {
      this.updateIntent('autospin-on');
    } else {
      this.updateIntent('autospin-off');
    }
  }

  private handleClick(): void {
    if (this.isAutoSpins) {
      setIsStopOnBalanceDecrease(false);
      setIsStopOnBalanceIncrease(false);
      setIsStopOnAnyWin(false);
      setIsStopOnWinExceeds(false);
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      this.isAutoSpins = false;
      this.updateIntent('autospin-off');
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false, 'autoplay btn click');
      }
      return;
    }
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.AUTOPLAY_POPUP);

      setIsOpenAutoplayPopup(!setIsOpenAutoplayPopup());
      // setIsOpenInfoPopup(false);
      // setIsOpenHistoryPopup(false);
      // setIsOpenBetSettingsPopup(false);
      setIsPopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(): void {
    this.setDisable(
      !isRegularMode(Logic.the.controller.gameMode) ||
        (this.isSlotBusy && !this.isAutoSpins) ||
        setIsPopupOpened() ||
        setIsContinueAutoSpinsAfterFeature(),
    );
  }

  public override handlePosition(): void {
    this.btn.anchor.set(1, 1);
    this.count.text.anchor.set(1, 0.5);

    const ratio = this.applicationSize.width / this.applicationSize.height > 2.15;
    let deviceSize = 0;
    let btnSize = ratio ? BTN_UI_DESKTOP_H : BTN_UI_DESKTOP_W;
    let btnGap = ratio ? BTN_GAP_DESKTOP_H : BTN_GAP_DESKTOP_W;

    if (isTabletLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_TABLET_H : BTN_UI_TABLET_W;
      btnGap = ratio ? BTN_GAP_TABLET_H : BTN_GAP_TABLET_W;
    }
    if (isTabletPortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_TABLET_W;
      btnGap = BTN_GAP_TABLET_W;
    }
    if (isMobileLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_MOBILE_H : BTN_UI_MOBILE_W;
      btnGap = ratio ? BTN_GAP_MOBILE_H : BTN_GAP_MOBILE_W;
    }
    if (isMobilePortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_MOBILE_W;
      btnGap = BTN_GAP_MOBILE_W;
    }

    if (ratio) {
      deviceSize = this.applicationSize.height;
    } else {
      deviceSize = this.applicationSize.width;
    }

    if (this.isPortraitMode) {
      deviceSize = this.applicationSize.height;
    }

    const size = calcPercentage(deviceSize, btnSize);
    const gap = calcPercentage(deviceSize, btnGap);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height / 2 - size - gap;
    this.setSize(size);

    this.count.text.y = -this.btn.height / 2;
    this.count.text.x = -(this.btn.width + this.btn.width * 0.1);

    if (this.isPortraitMode) {
      this.btn.anchor.set(0, 0.5);
      x = this.applicationSize.width / 2 + size + gap;
      y = this.applicationSize.height - size * 4 - gap;
      this.count.text.anchor.set(0.5, 0);
      this.count.text.y = -(this.btn.height + this.btn.height * 0.2);
      this.count.text.x = this.btn.width / 2;
    }

    this.setScaleCountText();

    this.x = x;
    this.y = y;
  }
}

export default AutoplayBtn;
