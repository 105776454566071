import { utils } from 'pixi.js';

export * from './textStyles';

class CustomEmitter extends utils.EventEmitter {
  override on(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.on(event, fn, context);
  }

  override once(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.once(event, fn, context);
  }

  override addListener(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.addListener(event, fn, context);
  }

  override emit(event: string | symbol, ...args: unknown[]): boolean {
    return super.emit(event, ...args);
  }
}

export const eventManager = new CustomEmitter();

(window as unknown as { eventManager: CustomEmitter }).eventManager = eventManager;

export enum PopupTypes {
  BUY_FEATURE,
  FREE_SPINS,
  FREE_SPINS_END,
}

export const GAME_FPS = 60;

// BASE WIN SETTINGS
export const DOUBLE_WIN_LIMIT = 2;
export const BASE_WIN_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;

// BIG WIN SETTINGS
export const BIG_WIN_LIMIT = 20;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;

// MEGA WIN SETTINGS
export const MEGA_WIN_LIMIT = 30;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// SUPER WIN SETTINGS
export const SUPER_WIN_LIMIT = 40;
export const SUPER_WIN_COUNT_UP_MULTIPLIER = 0.2;

// ULTRA WIN SETTINGS
export const ULTRA_WIN_COUNT_UP_MULTIPLIER = 0.5;

// ---- UI SIZES ----
//DESKTOP
export const BTN_PLAY_DESKTOP_W = 12.08; // 232 - 1920w
export const BTN_PLAY_DESKTOP_H = 21.5; // 232 - 1080h
export const BTN_SPIN_DESKTOP_W = 10; // 192 - 1920w
export const BTN_SPIN_DESKTOP_H = 17.8; // 192 - 1080h
export const BTN_UI_DESKTOP_W = 5; // 96 - 1920w
export const BTN_UI_DESKTOP_H = 8.9; // 96 - 1080h
export const BTN_GAP_DESKTOP_W = 1.67; // 32 - 1920w
export const BTN_GAP_DESKTOP_H = 2.96; // 32 - 1080h
//TABLET
export const BTN_PLAY_TABLET_W = 9.83; // 116 - 1180w
export const BTN_PLAY_TABLET_H = 14.15; // 116 - 820h
export const BTN_SPIN_TABLET_W = 8.14; // 96 - 1180w
export const BTN_SPIN_TABLET_H = 11.71; // 96 - 820h
export const BTN_UI_TABLET_W = 4.06; // 48 - 1180w
export const BTN_UI_TABLET_H = 5.85; // 48 - 820h
export const BTN_GAP_TABLET_W = 1.36; // 16 - 1180w
export const BTN_GAP_TABLET_H = 1.95; // 16 - 820h
//MOBILE
export const BTN_PLAY_MOBILE_W = 13.75; // 116 - 844w
export const BTN_PLAY_MOBILE_H = 29.74; // 116 - 390h
export const BTN_SPIN_MOBILE_W = 11.37; // 96 - 844w
export const BTN_SPIN_MOBILE_H = 24.62; // 96 - 390h
export const BTN_UI_MOBILE_W = 5.69; // 48 - 844w
export const BTN_UI_MOBILE_H = 12.31; // 48 - 390h
export const BTN_GAP_MOBILE_W = 1.9; // 16 - 844w
export const BTN_GAP_MOBILE_H = 4.1; // 16 - 390h

// GENERAL SLOT MACHINE SETTINGS
export const SLOT_SCALE = 1;
export const REELS_AMOUNT = 5;
export const SLOTS_PER_REEL_AMOUNT = 3;
export const REEL_WIDTH = 250;
export const SLOT_WIDTH = 250;
export const SLOT_HEIGHT = 250;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const GAME_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;

// REELS SETTINGS
export const BASE_REEL_STARTING_DURATION = 500;
export const TURBO_REEL_STARTING_DURATION = 250;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
export enum ReelState {
  IDLE,
  DISAPPEARING,
  WAITING,
  APPEARING,
}

// COUNT UP
export const COUNT_UP_MESSAGE_X = 0;
export const COUNT_UP_MESSAGE_Y = 425;

// SPIN ANIMATION SETTINGS
export const BASE_REEL_STARTING_FORMULA = (t: number): number => 3 * t * t * (1.4 * t - 0.4);
export const BASE_REEL_ENDING_FORMULA = (t: number): number => (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.1;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  SuperWin,
  UltraWin,
}

// FS
export const FREE_SPINS_FADE_DURATION = 4000;
export const FREE_SPINS_TIME_OUT_BANNER = 1000;
