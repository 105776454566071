import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';
import { GameMode } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';

class Frame extends ViewContainer {
  public frame = new Spine(Loader.shared.resources['animatedFrame']!.spineData!);

  constructor() {
    super();
    this.sortableChildren = true;
    this.init();
  }

  private init(): void {
    this.frame.name = 'reelFrame';
    this.frame.state.setAnimation(0, 'reel_ambient', true);
    this.frame.y = 65;
    this.frame.x = 0;
    this.frame.scale.set(1.1);
    this.zIndex = 9;
    this.interactiveChildren = false;
    this.addChild(this.frame);
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  public handleBaseModeChanges(): void {
    // this.frame.texture = Texture.from(ResourceTypes.frame);
  }
}

export default Frame;
