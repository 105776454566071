import { TextStyle } from 'pixi.js';

export const titleBuyBonusTextStyles = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fontFamily: 'Noto Sans',
  fontSize: 40,
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  fontWeight: '900',
  lineJoin: 'round',
});

export const buyBonusBetTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFFFFF',
  fontFamily: 'Noto Sans',
  fontSize: 28,
  fontWeight: '700',
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  lineJoin: 'round',
});

export const buyBonusBetValueTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFF7AE',
  fontFamily: 'Noto Sans',
  fontSize: 40,
  fontWeight: '900',
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  lineJoin: 'round',
});

export const buyBonusTotalCostTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFFFFF',
  fontFamily: 'Noto Sans',
  fontSize: 28,
  fontWeight: '700',
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  lineJoin: 'round',
});

export const buyBonusTotalCostValueTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFF7AE',
  fontFamily: 'Noto Sans',
  fontSize: 40,
  fontWeight: '900',
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  lineJoin: 'round',
});
