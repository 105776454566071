import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApplicationError, EErrorCodeClient, EErrorKind } from '@money.energy/common-errors';
import { fallBackReelPosition } from '../gameUtils';
import i18n from '../i18next';
import { setGameError, setIsRevokeThrowingError, setIsTimeoutErrorMessage, setSlotConfig } from './cache';
import { isStoppedGql } from './query';
import typePolicies from './typePolices';

const REST_URL = process.env['REACT_APP_URL'] as string;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
  if (graphQLErrors) {
    setIsRevokeThrowingError(true);
    fallBackReelPosition();
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      const { message, extensions } = err;
      setIsRevokeThrowingError(true);
      const e = ApplicationError.getShapeByAppCode(extensions?.['applicationCode'] as number);
      if (e.kind === EErrorKind.CLIENT) {
        if (e.code === EErrorCodeClient.INSUFFICIENT_FUNDS) {
          setGameError({
            show: true,
            type: 'balance',
            message: i18n.t([extensions?.['i18nKey'] as string, 'errors.UNKNOWN.UNKNOWN']) || message,
          });
          return;
        }
      }

      setGameError({
        show: true,
        type: 'network',
        message:
          i18n.t([
            (extensions && (extensions['i18nKey'] as string)) || 'errors.UNKNOWN.UNKNOWN',
            'errors.UNKNOWN.UNKNOWN',
          ]) || message,
      });
    }
  } else if (networkError) {
    setIsRevokeThrowingError(true);
    fallBackReelPosition();

    setGameError({
      show: true,
      type: 'network',
      message: i18n.t('errors.UNKNOWN.NETWORK'),
    });
  } else {
    setIsRevokeThrowingError(true);
    fallBackReelPosition();

    setGameError({
      show: true,
      type: 'network',
      message: i18n.t('errors.UNKNOWN.UNKNOWN'),
    });
  }
});

const connectionParams = () => {
  const { sessionId } = setSlotConfig();
  return {
    Authorization: sessionId,
  };
};

const authLink = setContext((_) => {
  return {
    headers: {
      ...connectionParams(),
    },
  };
});

const httpLink = new HttpLink({
  uri: REST_URL,
});

const cache = new InMemoryCache({
  typePolicies,
});

cache.writeQuery({
  query: isStoppedGql,
  data: {
    isSlotStopped: true,
  },
});

const client = new ApolloClient({
  link: authLink.concat(from([errorLink, httpLink])),
  cache,
});

export default client;
