import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { EventTypes } from '../../global.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager, multipliersStyle } from '../config';

class MultipliersAnimation extends ViewContainer {
  private multipliersAnimation: Spine | null = null;
  private m1 = new TextField('x1', 100, 100, multipliersStyle);
  private m2 = new TextField('x2', 100, 100, multipliersStyle);
  private m3 = new TextField('x3', 100, 100, multipliersStyle);
  private m4 = new TextField('x4', 100, 100, multipliersStyle);
  constructor() {
    super();
    this.zIndex = 3;
    this.initAnimation();
    this.m1.text.anchor.set(0.5);
    this.m1.text.x = -10;
    this.m1.text.y = 2;
    this.m2.text.anchor.set(0.5);
    this.m2.text.x = -10;
    this.m3.text.anchor.set(0.5);
    this.m3.text.x = -10;

    eventManager.on(EventTypes.START_MULTIPLIERS_ANIMATION, this.startAnimation.bind(this));
  }

  private initAnimation(): void {
    this.multipliersAnimation = new Spine(PIXI.Loader.shared.resources['multipliers']!.spineData!);
    this.addChild(this.multipliersAnimation);
  }

  private startAnimation(multipliers: number[], callback: () => void): void {
    const listener = {
      start: () => {
        this.m1.text.text = `x${multipliers[0]}`;
        this.m2.text.text = `x${multipliers[1]}`;
        this.m3.text.text = `x${multipliers[2]}`;
      },
      complete: () => {
        this.m1.text.text = '0';
        this.m2.text.text = '0';
        this.m3.text.text = '0';
        callback();
        this.multipliersAnimation!.state.removeListener(listener);
      },
      event: () => {
        this.m1.text.text = `x${multipliers[0] * multipliers[1] * multipliers[2]}`;
      },
    };

    this.multipliersAnimation!.state.addListener(listener);

    this.multipliersAnimation!.state.setAnimation(0, 'Multiplier_Win', false);
    this.multipliersAnimation!.skeleton.findSlot('sum1_place_holder').currentSprite.texture = PIXI.Texture.EMPTY;
    this.multipliersAnimation!.skeleton.findSlot('sum1_place_holder').currentSprite.addChild(this.m2.getText());

    this.multipliersAnimation!.skeleton.findSlot('sum2_place_holder').currentSprite.texture = PIXI.Texture.EMPTY;
    this.multipliersAnimation!.skeleton.findSlot('sum2_place_holder').currentSprite.addChild(this.m3.getText());

    this.multipliersAnimation!.skeleton.findSlot('main_place_holder').currentSprite.texture = PIXI.Texture.EMPTY;
    this.multipliersAnimation!.skeleton.findSlot('main_place_holder').currentSprite.addChild(this.m1.getText());
  }

  public override resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;
    const bgAspectRatio = 2000 / 2000;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / 2000);
    } else {
      this.scale.set(width / 2000);
    }
  }
}
export default MultipliersAnimation;
